import React from "react";
import Modal from "react-bootstrap/Modal";
import DisclaimerText from "./DisclaimerText";


const DisclaimerModal = ({ showModal, onClose }) => {
  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Terms of Service</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DisclaimerText />
      </Modal.Body>
    </Modal>
  );
};

export default DisclaimerModal;
