import React from "react";
import DisclaimerModal from "./DisclaimerModal";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import { Link } from "react-router-dom";


const Footer = ({
  showDisclaimerModal,
  setShowDisclaimerModal,
  showPrivacyPolicyModal,
  setShowPrivacyPolicyModal,
}) => {
  return (
    <footer
      style={{ textAlign: "center", padding: "20px", background: "#f7f7f7" }}
    >
      {" "}
      <Link
        to="/tos"
        style={{ marginRight:"10px"}}
        onClick={(e) => {
          e.preventDefault();
          setShowDisclaimerModal(true);
        }}
      >
        Terms of Service
      </Link>
      <Link
        to="/privacy-policy"
        onClick={(e) => {
          e.preventDefault();
          setShowPrivacyPolicyModal(true);
        }}
      >
        Privacy Policy
      </Link>
      <br />
      &copy; {new Date().getFullYear()} Reefer Seeker, LLC
      {/* The Modals */}
      <DisclaimerModal
        showModal={showDisclaimerModal}
        onClose={() => setShowDisclaimerModal(false)}
      />
      <PrivacyPolicyModal
        showModal={showPrivacyPolicyModal}
        onClose={() => setShowPrivacyPolicyModal(false)}
      />
    </footer>
  );
};

export default Footer;
