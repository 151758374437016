import React, { useState, useContext } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { AuthContext } from "../../context/AuthContext";

const GoogleLoginButton = ({onGoogleLoginSuccess}) => {
  const [error, setError] = useState(null);
  const clientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const { userActions, navigate } = useContext(AuthContext);

  const responseGoogle = (credentialResponse) => {
    console.log("Google response:", credentialResponse);
    if (credentialResponse.error) {
      setError("Error logging in with Google.");
      return;
    }
    const dataToSend = { access_token: credentialResponse.credential };
    console.log("Data being sent to backend:", dataToSend); // Add this line
     userActions
       .loginWithGoogle(dataToSend)
       .then(() => {
         if (onGoogleLoginSuccess) {
           onGoogleLoginSuccess();
         } else {
           navigate("/");
         }
       })
       .catch(() => {
         setError("Error logging in with Google.");
       });
  };

  return (
    <div className="google-button-container">
      {error && <p>{error}</p>}
      <GoogleLogin
        clientId={clientID}
        onSuccess={responseGoogle}
        onError={responseGoogle}
       
      />
    </div>
  );
};

export default GoogleLoginButton;
