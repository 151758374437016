import  React from "react"; 
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./scss/main.scss";
import ReactGA from "react-ga4"
import { AuthProvider } from "./context/AuthContext";
import App from "./App";
import { FilterContextProvider } from "./context/FilterContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

// Creating the root application component
const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA.initialize("G-CTR4HQYVV5");

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <BrowserRouter>
      <AuthProvider>
        <FilterContextProvider>
          <App />
        </FilterContextProvider>
      </AuthProvider>
    </BrowserRouter>
  </GoogleOAuthProvider>
);
