import React from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";

const DisclaimerText = () => {
  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <h4>
            <strong>Terms of Service (Disclaimer):</strong>
          </h4>
          <p>
            The following disclaimer ("Disclaimer") governs your use of
            ReeferSeeker.io ("Website"). By accessing and using this Website,
            you acknowledge and agree to the terms and conditions set forth in
            this Disclaimer. Please read this Disclaimer carefully before
            proceeding.
          </p>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <strong>1. Eligibility and Legal Compliance:</strong>
              <ListGroup>
                <ListGroup.Item>
                  1.1 By entering and using this Website, you affirm and warrant
                  that you are at least the legal age to consume medical
                  marijuana in the state in which you reside, and you hold an
                  active and valid medical marijuana license as required by the
                  laws and regulations of your state.
                </ListGroup.Item>
                <ListGroup.Item>
                  1.2 You further acknowledge and agree that your use of this
                  Website is subject to all applicable laws and regulations
                  related to the possession, use, and distribution of medical
                  marijuana in your jurisdiction.
                </ListGroup.Item>
              </ListGroup>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>2. Registration Requirement:</strong>
              <ListGroup>
                <ListGroup.Item>
                  2.1 All users of ReeferSeeker.io are required to register and
                  create an account to access certain features and services
                  offered on the Website.
                </ListGroup.Item>
                <ListGroup.Item>
                  2.2 By registering, you agree to provide accurate, current,
                  and complete information about yourself as prompted by the
                  registration form, and you will maintain the accuracy of such
                  information during the course of your usage.
                </ListGroup.Item>
                <ListGroup.Item>
                  2.3 You are solely responsible for safeguarding your account
                  login credentials and ensuring that they are not shared or
                  used by any other person or entity. ReeferSeeker.io shall not
                  be held liable for any unauthorized access or use of your
                  account.
                </ListGroup.Item>
              </ListGroup>
            </ListGroup.Item>

            {/* ... Continuing the rest of the content ... */}

            <ListGroup.Item>
              <strong>3. Entertainment Purposes Only:</strong>
              <ListGroup>
                <ListGroup.Item>
                  3.1 ReeferSeeker.io is a platform designed and intended for
                  entertainment purposes only. The content, information, and
                  services provided on this Website are not to be construed as
                  professional advice or recommendations concerning medical
                  marijuana, its use, or any other matter.
                </ListGroup.Item>
                <ListGroup.Item>
                  3.2 The content available on this Website, including but not
                  limited to prices, product descriptions, and other related
                  information, is provided solely for informational purposes.
                  ReeferSeeker.io does not endorse or guarantee the accuracy,
                  completeness, or validity of any information displayed on the
                  Website.
                </ListGroup.Item>
              </ListGroup>
            </ListGroup.Item>

            {/* ... And so on for all sections ... */}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default DisclaimerText;
