import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const FilterContext = createContext();

const defaultFilters = {
  category: "Flower",
  state: "FL",
  zipcode: "33445",
  distance: 5,
};

const categories = [
  "Flower",
  "Vaporizers",
  "Concentrates",
  "Edibles",
  "Orals",
  "Apparel",
  "Pre-Rolls",
  "Tinctures",
  "Topicals",
];

const states = [
  "FL",
  "AZ",
  "CA",
  "CO",
  "CT",
  "IL",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "ND",
  "NJ",
  "NV",
  "NY",
  "OH",
  "OR",
  "PA",
  "RI",
  "UT",
  "VA",
  "WV",
];

const getRandomCategory = () => {
  return categories[Math.floor(Math.random() * categories.length)];
};
const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const getLocationData = async (lat, lng) => {
  console.log("Fetching location data...");
  const result = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
  );
  console.log("Location data fetched successfully", result.data);
  const fullAddress = result.data.results[0].formatted_address;
  console.log("Full address:", fullAddress);
  const match = fullAddress.match(/^(.*?),\s*(.*?),\s*([A-Za-z]*\s*\d*)/);
  const city = match[2];
  const stateAndZip = match[3];
  const address = `${city}, ${stateAndZip}`;
  const zipcode = address.slice(-5);
  const state = address.split(",")[1].trim().split(" ")[0];

  if (!states.includes(state)) {
    throw new Error("State not in predefined list");
  }

  return {
    address,
    filters: {
      state,
      zipcode,
      distance: defaultFilters.distance,
      category: getRandomCategory(),
    },
  };
};

export const FilterContextProvider = ({ children }) => {
  const [filters, setFilters] = useState(defaultFilters);
  const [address, setAddress] = useState("");
  const [randomCategory, setRandomCategory] = useState(getRandomCategory());

  const applyDefaultFilters = () => {
    console.log("Applying default filters...");
    setFilters({ ...defaultFilters, category: randomCategory });
    setAddress("");
  };

  const fetchInitialLocation = async () => {
    if (!navigator.geolocation) {
      applyDefaultFilters();
      return;
    }

    try {
      console.log("Fetching initial location...");
      const { latitude: lat, longitude: lng } = await new Promise(
        (resolve, reject) =>
          navigator.geolocation.getCurrentPosition(resolve, reject)
      ).then((position) => position.coords);

      const locationData = await getLocationData(lat, lng);
      locationData.filters.category = randomCategory;
      setFilters(locationData.filters);
      setAddress(locationData.address);
      console.log("Initial location fetched successfully");
    } catch (error) {
      console.error("Error getting geolocation:", error);
      applyDefaultFilters();
    }
  };

  useEffect(() => {
    console.log("Fetching initial data...");
    fetchInitialLocation();
  }, []);

  return (
    <FilterContext.Provider
      value={{
        filters,
        setFilters,
        address:
          address || `${defaultFilters.state}, ${defaultFilters.zipcode}`,
        setAddress,
        categories,
        states,
        getRandomCategory,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
