import { useState, useEffect, useContext, useCallback } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import NavbarComponent from "./components/Navbar";
import HomePage from "./pages/Home";
import { AuthContext } from "./context/AuthContext";
import Registration from "./pages/RegistrationModal";
import Login from "./pages/LoginModal";
import NotSignedIn from "./pages/NotSignedIn";
import Disclaimer from "./components/Disclaimer";
import ContactModal from "./pages/ContactModal";
import LogoutComponent from "./components/Logout";
import PrivacyPolicyModal from "./components/PrivacyPolicyModal";
import DisclaimerModal from "./components/DisclaimerModal";
import Footer from "./components/Footer";
function App() {
  const { isAuthenticated, hasLoggedOut, setHasLoggedOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const location = useLocation();
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const[showContactModal, setShowContactModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleShowLoginModal = useCallback(() => {
    console.log("Showing login modal");
    setShowLoginModal(true);
  }, []);

  const handleCloseLoginModal = useCallback(() => {
    console.log("Closing login modal");
    setShowLoginModal(false);
  }, []);

  const handleShowContactModal = useCallback(() => {
    console.log("Showing contact modal");
    setShowContactModal(true);
  }, []);

  const handleCloseContactModal = useCallback(() => {
    console.log("Closing contact modal");
    setShowContactModal(false);
  }, []);

  const handleShowRegistrationModal = useCallback(() => {
    console.log("Showing registration modal");
    setShowRegistrationModal(true);
  }, []);

  const handleCloseRegistrationModal = useCallback(() => {
    console.log("Closing registration modal");
    setShowRegistrationModal(false);
  }, []);

  const handleRegisterSuccess = useCallback(() => {
    console.log("Register success");
    setShowRegistrationModal(false);
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    
    if (!isAuthenticated) {
      if (hasLoggedOut) {
        console.log("User has logged out");
        navigate("/loggedout");
        setHasLoggedOut(true); // reset it for future checks
      } else {
        console.log("User not authenticated");
        navigate("/notsignedin");
      }
    } else {
      console.log("User authenticated");
      navigate("/");
    }
    setIsLoading(false);
  }, [isAuthenticated, navigate, setIsLoading, hasLoggedOut, setHasLoggedOut]);

  useEffect(() => {
    if (location.pathname === "/tos") {
      setShowDisclaimerModal(true);
    } else {
      setShowDisclaimerModal(false);
    }

    if (location.pathname === "/privacy-policy") {
      setShowPrivacyPolicyModal(true);
    } else {
      setShowPrivacyPolicyModal(false);
    }
  }, [location.pathname]);


  if (isLoading) {
    console.log("Loading...");
    return <div>Loading...</div>;
  }

  return (
    <ErrorBoundary>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "sticky",
          top: "0",
          zIndex: "100",
        }}
      >
        <header>
          <NavbarComponent
            onLoginClick={handleShowLoginModal}
            onRegisterClick={handleShowRegistrationModal}
            onContactClick={handleShowContactModal}
          />
        </header>
      </div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/notsignedin" element={<NotSignedIn />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/loggedout" element={<LogoutComponent />} />
        
      </Routes>
      <Login showModal={showLoginModal} onClose={handleCloseLoginModal} />
      <ContactModal
        showModal={showContactModal}
        onClose={handleCloseContactModal}
      />
      <Registration
        showModal={showRegistrationModal}
        onClose={handleCloseRegistrationModal}
        onRegisterSuccess={handleRegisterSuccess}
      />
      <DisclaimerModal
        showModal={showDisclaimerModal}
        onClose={() => setShowDisclaimerModal(false)}
      />
      <PrivacyPolicyModal
        showModal={showPrivacyPolicyModal}
        onClose={() => setShowPrivacyPolicyModal(false)}
      />
      <Footer
        showDisclaimerModal={showDisclaimerModal}
        setShowDisclaimerModal={setShowDisclaimerModal}
        showPrivacyPolicyModal={showPrivacyPolicyModal}
        setShowPrivacyPolicyModal={setShowPrivacyPolicyModal}
      />
    </ErrorBoundary>
  );
}

export default App;
