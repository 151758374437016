import React from "react";
import Modal from "react-bootstrap/Modal";
import PrivacyPolicy from "./PrivacyPolicy";


const PrivacyPolicyModal = ({ showModal, onClose }) => {
  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PrivacyPolicy />
      </Modal.Body>
    </Modal>
  );
};

export default PrivacyPolicyModal;
