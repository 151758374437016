import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";

function ContactForm({onClose}) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState({
    email: "",
    subject: "",
    message: "",
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const contactForm = event.currentTarget;
    if (contactForm.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    // Make an Axios call to send the inquiry
    axios
      .post(`${apiUrl}/contact`, form)
      .then((response) => {
        if (response.status === 201) {
          setSuccess(true);
          setError(null);
          if (onClose){
            onClose();
          
          }
        }
      })
        .catch((err) => {
        if (err.response) {
            console.error("Server responded with:", err.response.data);
        } else {
            console.error("Error sending inquiry:", err);
        }
        setError("Failed to send the inquiry. Please try again.");
    });
};
  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          value={form.email}
          onChange={(e) => setForm({ ...form, email: e.target.value })}
          required
          type="email"
          placeholder="Enter email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Subject</Form.Label>
        <Form.Control
          value={form.subject}
          onChange={(e) => setForm({ ...form, subject: e.target.value })}
          required
          type="text"
          placeholder="Subject of your inquiry"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Message</Form.Label>
        <Form.Control
          value={form.message}
          onChange={(e) => setForm({ ...form, message: e.target.value })}
          required
          as="textarea"
          rows={3}
          placeholder="Your message here"
        />
      </Form.Group>

      {error && <p className="text-danger">{error}</p>}
      {success && <p className="text-success">Inquiry sent successfully!</p>}

      <Button variant="primary" type="submit">
        Send Inquiry
      </Button>
    </Form>
  );
}

export default ContactForm;
