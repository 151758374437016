import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import DisclaimerModal from "./DisclaimerModal";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import Login from "../pages/LoginModal";
import Registration from "../pages/RegistrationModal";

class Disclaimer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showMainDisclaimer: true,
      showDisclaimer: false,
      showPrivacy: false,
      showLogin: false,
      showRegister: false,
    };
  }

  handleCloseMainDisclaimer = () => {
    this.setState({ showMainDisclaimer: false });
    // Redirect to Google
    window.location.href = "http://www.google.com";
  };

  handleLoginOpen = () => {
    this.setState({ showLogin: true });
  };

  handleRegisterOpen = () => {
    this.setState({ showRegister: true });
  };

  handleLoginClose = () => {
    this.setState({ showLogin: false });
  };

  handleRegisterClose = () => {
    this.setState({ showRegister: false });
  };

  handleOpenDisclaimerModal = () => {
    this.setState({ showDisclaimer: true });
  };

  handleCloseDisclaimerModal = () => {
    this.setState({ showDisclaimer: false });
  };

  handleOpenPrivacy = () => {
    this.setState({ showPrivacy: true });
  };

  handleClosePrivacy = () => {
    this.setState({ showPrivacy: false });
  };

  render() {
    return (
      <>
        {/* Main Disclaimer Modal */}
        <Modal
          show={this.state.showMainDisclaimer}
          onHide={this.handleCloseMainDisclaimer}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="modal-title">
              Welcome to ReeferSeeker!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="disclaimer-modal">
            In order to use this site, prove your eligible to view this material, and to protect the integrity of our data,
            you will need to agree to our
            <Button variant="link" onClick={this.handleOpenDisclaimerModal}>
              Terms of Service,
            </Button>
            <Button variant="link" onClick={this.handleOpenPrivacy}>
              Privacy Policy,
            </Button>
            and register.<br/> 
            We are just starting out, if you are a dispensary and
            would like to add your inventory, please contact {""}
            <a href="mailto:support@reeferseeker.io">support@reeferseeker.io</a>{" "}
             If you are a registered user and would like us to contact a
            dispensary not listed please let us know via the contact form or
            support email.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.handleCloseMainDisclaimer}
            >
              Close
            </Button>
            <Button
              variant="secondary"
              className="btn-login"
              onClick={this.handleLoginOpen}
            >
              Login
            </Button>
            <Button
              variant="secondary"
              className="btn-register"
              onClick={this.handleRegisterOpen}
            >
              Register
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Disclaimer Modal */}
        <DisclaimerModal
          showModal={this.state.showDisclaimer}
          onClose={this.handleCloseDisclaimerModal}
        />

        {/* Privacy Policy Modal */}
        <PrivacyPolicyModal
          showModal={this.state.showPrivacy}
          onClose={this.handleClosePrivacy}
        />

        {/* Login Modal */}
        <Login
          showModal={this.state.showLogin}
          onClose={this.handleLoginClose}
        />

        {/* Registration Modal */}
        <Registration
          showModal={this.state.showRegister}
          onClose={this.handleRegisterClose}
        />
      </>
    );
  }
}

export default Disclaimer;
