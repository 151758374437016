import React from "react";
import { Container, Row, Col, Table, ListGroup } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <h4>
            <strong>Privacy Policy</strong>
          </h4>
          <p>
            This Privacy Policy ("Policy") explains how ReeferSeeker collects,
            uses, and stores personal information when an individual ("you")
            uses our cannabis product price aggregation service ("Service")
            through our website{" "}
            <a href="http://www.reeferseeker.io">www.reeferseeker.io</a> (the
            "Website"). The Service and the Website are collectively referred to
            as the "Solution."
          </p>

          <h5>
            <strong>Personal Information We Collect</strong>
          </h5>
          <p>
            We acquire information from you through various touchpoints,
            including your interactions with our website and other forms of
            engagement. Some of this information is furnished directly by you,
            through mechanisms such as Google authentication or our standard
            registration procedures. Additionally, we collect data pertaining to
            your usage patterns, interactions, and overall experience with our
            website.
          </p>
          <p>
            Personal information is data that can be used to identify or contact
            an individual person. ReeferSeeker has collected the following
            categories of personal information from its users in the last twelve
            (12) months:
          </p>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Categories</th>
                <th>Examples</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Identifiers</td>
                <td>
                  Name, alias (nickname), email address, phone number, date of
                  birth, IP address, other similar identifiers
                </td>
              </tr>
              <tr>
                <td>
                  Internet or other electronic network activity information
                </td>
                <td>
                  Browsing history, search history, and information regarding a
                  user's interaction with the Solution.
                </td>
              </tr>
              <tr>
                <td>Geolocation data</td>
                <td>Physical location, generally.</td>
              </tr>
            </tbody>
          </Table>

          <h5>
            <strong>How ReeferSeeker Uses Personal Information</strong>
          </h5>
          <p>
            Personal Information you provide to us is used to help us
            communicate with you and to provide you with the Service. For
            example, we use personal information to:
          </p>
          <ListGroup variant="flush">
            <ListGroup.Item>
              Register and service your ReeferSeeker account.
            </ListGroup.Item>
            <ListGroup.Item>
              Solicit feedback and respond to your questions.
            </ListGroup.Item>
            <ListGroup.Item>Provide technical support.</ListGroup.Item>
            <ListGroup.Item>
              Inform you about product or promotional offers.
            </ListGroup.Item>
          </ListGroup>
          <p>
            In order for the dispensary you select to process your order, we
            will send them your nickname, email address, phone number,
            reservation time, delivery location, and the specific products and
            quantities you ordered. We may also provide the dispensary with
            suggested products for you based upon information that we gather
            about your shopping history or consumers with similar shopping
            histories.
          </p>

          <h5>
            <strong>Non-Personal Information</strong>
          </h5>
          <p>
            We also collect non-personal information, which does not identify
            any specific individual. This information is used to improve the
            Solution and customize the user experience, or for any other
            legitimate purpose. We may aggregate non-personal information to
            track trends and analyze use patterns on the Solution.
          </p>

          <h5>
            <strong>Cookies and Other Technologies</strong>
          </h5>
          <p>
            ReeferSeeker uses third-party analytic and tracking tools to
            understand how people use the Service and improve its effectiveness.
            Third-party companies may use cookies or other technologies to
            collect and store anonymous information. We do not have access to,
            nor control over, third parties' use of cookies or tracking
            technologies.
          </p>

          <h5>
            <strong>Sharing Information with Third Parties</strong>
          </h5>
          <p>
            We may disclose certain categories of personal information to third
            parties for business purposes, such as providing the Service,
            customer support, and analyzing Solution usage. We do not sell
            personal information to third parties. However, we may disclose
            information to unaffiliated third parties for advertising and
            marketing purposes.
          </p>

          <h5>
            <strong>Messaging</strong>
          </h5>
          <p>
            By providing your mobile phone number and/or email address, you
            consent to receive informational and transactional messages related
            to the Service. You may opt-out of receiving marketing messages at
            any time.
          </p>

          <h5>
            <strong>Protecting Your Personal Information</strong>
          </h5>
          <p>
            ReeferSeeker and our service providers implement security measures
            to protect your information from unauthorized access, disclosure, or
            accidental loss or destruction.
          </p>

          <h5>
            <strong>Your Privacy Rights</strong>
          </h5>
          <p>
            You can control your personal data that ReeferSeeker obtains and
            exercise your data protection rights by contacting ReeferSeeker. You
            may choose to unsubscribe from promotional offers or limit location
            access.
          </p>

          <h5>
            <strong>Our Policies Concerning Children</strong>
          </h5>
          <p>
            You must be at least 18 years old and a valid medical marijuana
            patient or 21 years old to create an account on the Website. We do
            not knowingly collect personal information from anyone under these
            ages.
          </p>

          <h5>
            <strong>Users Outside of The United States</strong>
          </h5>
          <p>
            The Service and servers are located in the United States and
            intended for use by individuals in the United States. By using the
            Solution and submitting Personal Information, you authorize this
            transfer. ReeferSeeker is not designed for users in the European
            Union.
          </p>

          <h5>
            <strong>Changes to Our Privacy Policy</strong>
          </h5>
          <p>
            We may update this Privacy Policy from time to time, and the updated
            version will be posted on the Website. By continuing to use the
            Solution, you accept the updated Privacy Policy.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
