import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationComponent = ({ currentPage, totalPages, onPageChange, previousPage, nextPage }) => {
  const paginationItems = [];
  const leftSide = currentPage - 2 > 0 ? currentPage - 2 : 1;
  const rightSide = currentPage + 2 <= totalPages ? currentPage + 2 : totalPages;

  // First and Previous buttons
  paginationItems.push(
    <Pagination.Item key="first" onClick={() => onPageChange(1)} disabled={currentPage === 1} />,
    <Pagination.Prev key="prev" onClick={() => onPageChange(previousPage)} disabled={currentPage === 1} />
  );

  // If there are pages to the left of the current page
  if (currentPage > 3) {
    paginationItems.push(<Pagination.Item key="1" onClick={() => onPageChange(1)}>1</Pagination.Item>);
    paginationItems.push(<Pagination.Ellipsis key="left-ellipsis" />);
  }

  // Pagination items around the current page
  for (let i = leftSide; i <= rightSide; i++) {
    paginationItems.push(
      <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
        {i}
      </Pagination.Item>
    );
  }

  // If there are pages to the right of the current page
  if (currentPage < totalPages - 2) {
    paginationItems.push(<Pagination.Ellipsis key="right-ellipsis" />);
    paginationItems.push(<Pagination.Item key={totalPages} onClick={() => onPageChange(totalPages)}>{totalPages}</Pagination.Item>);
  }

  // Next and Last buttons
  paginationItems.push(
    <Pagination.Next key="next" onClick={() => onPageChange(nextPage)} disabled={nextPage === null} />
  );

  return <Pagination>{paginationItems}</Pagination>;
};

export default PaginationComponent;
