import React, { useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import LoginForm from "../components/authentication/LoginForm";
import { useNavigate } from "react-router-dom";
import GoogleLoginButton from "../components/authentication/GoogleLogin";
const Login = ({ showModal, onClose }) => {
  const navigate = useNavigate();
  const handleLoginSuccess = useCallback(() => {
    onClose();
    navigate("/");
  }, [navigate, onClose]);
  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header>
        <Modal.Title className="login-centered-title">Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LoginForm onLoginSuccess={handleLoginSuccess} />
        <div className="mt-3 text-center">
          <p>
            <strong>Or</strong>
          </p>
        </div>
        <div className="google-button-container">
          <GoogleLoginButton onGoogleLoginSuccess={handleLoginSuccess} />
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default Login;
