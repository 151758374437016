import React from "react";
import ReactGA from "react-ga4";
import { Card, Button } from "react-bootstrap";
import "../scss/card.scss";

const CustomCard = ({ item, defaultImageUrl }) => {
  if (!item) {
    return null;
  }
  const handleClick = (url) => {
    // Tracking the click event with Google Analytics
    console.log("GA Event Triggered for:", url); // This will help debug
    ReactGA.event({
      category: "Card",
      action: "Clicked View on Site",
      label: url,
    });
  };

  return (
    <div className="custom-card d-flex flex-column">
      <Card.Body className="flex-grow-1">
        <Card.Title>{item.name}</Card.Title>
        <Card.Text className="card-text-responsive">
          {" "}
          {/* Add font size */}
          <strong>Distance:</strong>{" "}
          <em>
            {item.distance ? item.distance.toFixed(2) : ""} <em>miles</em>
          </em>
        </Card.Text>

        <Card.Img variant="top" src={item.image || defaultImageUrl} />
        <Card.Text className="brand-text">{item.brand}</Card.Text>
        <Card.Text className="centered-content">{item.product_name}</Card.Text>
        <Card.Text>
          <strong>{item.category}</strong>
        </Card.Text>
        <Card.Text>
          {item.weight} {item.strain} {item.subcategory}
        </Card.Text>
        <Card.Text>
          THC: {item.thc} CBD: {item.cbd}
        </Card.Text>
        <Card.Text>
          <span className="black-bold">Price:</span>{" "}
          <span className="green-price">
            {item.price ? <span>$</span> : ""}
            <span>{item.price ? item.price : "N/A"}</span>
          </span>
        </Card.Text>
        <Button
          className="custom-button"
          href={item.product_url || item.address2}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleClick(item.product_url || item.address2)}
        >
          View on Site
        </Button>
      </Card.Body>
    </div>
  );
};

export default CustomCard;
