import React from "react";
import { Modal } from "react-bootstrap";
import ContactForm from "../components/authentication/ContactForm";

const ContactModal = React.memo(({ showModal, onClose }) => {
  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Contact Us</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <ContactForm onClose={onClose}/>
      </Modal.Body>
    </Modal>
  );
});

export default ContactModal;
