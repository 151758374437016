import React, { useState, useContext, useEffect } from "react";
import { FilterContext } from "../context/FilterContext";
import {
  Navbar,
  Nav,
  Dropdown,
  Form,
  Button,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import logo from "../logo1.png";
import { AuthContext } from "../context/AuthContext";
const NavbarComponent = ({ onLoginClick, onRegisterClick, onContactClick }) => {
  const { filters, setFilters, address, states, categories } =
    useContext(FilterContext);

  const [filterState, setFilterState] = useState(filters);
  const handleFilterSubmit = (event) => {
    event.preventDefault();
    if (!filterState.zipcode || !filterState.distance) {
      console.error("Both zipcode and distance must be provided");
      return;
    }
    setFilters(filterState);
  };
  useEffect(() => {
    setFilterState(filters);
  }, [filters]);

  const { userActions, isAuthenticated, hasLoggedOut } = useContext(AuthContext);
  const iconColor = isAuthenticated && !hasLoggedOut ? "green" : "defaultColor";

  return (
    <div className="navbar-container">
      <Navbar bg="white" expand="lg" className="top-navbar">
        {/* Top Navbar Content */}
        <Container fluid>
          <Row className="w-100 no-gutters align-items-center">
            <Col xs={4} lg={2} className="d-flex align-items-center">
              <Nav.Link>{address}</Nav.Link>
            </Col>
            <Col xs={4} lg={8} className="text-center">
              <Navbar.Brand>
                <img
                  src={logo}
                  alt="logo"
                  className="logo"
                  style={{ maxHeight: "12rem" }}
                />
              </Navbar.Brand>
            </Col>
            <Col xs={4} lg={2} className="text-end">
              {/* Display the user icon on the upper right corner */}
              <Dropdown>
                <Dropdown.Toggle as={Nav.Link}>
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    size="2x"
                    style={{ color: iconColor }}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="my-custom-dropdown-menu"
                  align="right"
                >
                  {/* Show "Login" and "Register" only when user is NOT authenticated */}
                  {!isAuthenticated && (
                    <>
                      <Dropdown.Item onClick={onLoginClick}>
                        Login
                      </Dropdown.Item>
                      <Dropdown.Item onClick={onRegisterClick}>
                        Register
                      </Dropdown.Item>
                    </>
                  )}
                  {/* Always show "Contact Us" */}
                  <Dropdown.Item onClick={onContactClick}>
                    Contact Us
                  </Dropdown.Item>
                  {/* Show "Logout" only when user IS authenticated */}
                  {isAuthenticated && (
                    <Dropdown.Item onClick={userActions.logout}>
                      Logout
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Container>
      </Navbar>

      <Navbar
        bg="white"
        expand="lg"
        sticky="top"
        className="bottom-navbar justify-content-between"
      >
        <Container>
          <Form
            onSubmit={handleFilterSubmit}
            className="d-flex bg-white p-3 filter-form"
          >
            {/* Filter Form Content */}
            <Row className="mb-3 align-items-center my-2">
              <Col xs={12} sm={6} md={3}>
                <div className="form-field">
                  <Form.Label className="form-label">Category: </Form.Label>
                  <Form.Select
                    value={filterState.category}
                    onChange={(e) =>
                      setFilterState({
                        ...filterState,
                        category: e.target.value,
                      })
                    }
                  >
                    <option>Select category...</option>
                    {categories.map((cat, index) => (
                      <option key={index} value={cat}>
                        {cat}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <div className="form-field">
                  <Form.Label className="form-label">State: </Form.Label>
                  <Form.Select
                    value={filterState.state}
                    onChange={(e) =>
                      setFilterState({ ...filterState, state: e.target.value })
                    }
                  >
                    <option>Select state...</option>
                    {states.map((stateOption, index) => (
                      <option key={index} value={stateOption}>
                        {stateOption}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <div className="form-field">
                  <Form.Label className="form-label">Zipcode: </Form.Label>
                  <Form.Control
                    type="text"
                    value={filterState.zipcode}
                    onChange={(e) =>
                      setFilterState({
                        ...filterState,
                        zipcode: e.target.value,
                      })
                    }
                    placeholder="Enter zipcode"
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <div className="form-field">
                  <Form.Label className="form-label">
                    Distance (miles):{" "}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={filterState.distance}
                    onChange={(e) =>
                      setFilterState({
                        ...filterState,
                        distance: e.target.value,
                      })
                    }
                    placeholder="Enter distance"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-3 align-items-center my-2">
              <Col xs={12} className="d-flex justify-content-center">
                {/* The Apply Filters button */}
                <Form.Group className="d-flex align-items-end">
                  <Button type="submit" className="custom-button">
                    Apply Filters
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavbarComponent;
