import React, { useState, useContext, useCallback } from "react";
import { Form, Button } from "react-bootstrap";
import { AuthContext } from "../../context/AuthContext";

function RegistrationForm({ onRegisterSuccess }) {
  const { userActions } = useContext(AuthContext);
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
  });
  const [error, setError] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const registrationForm = event.currentTarget;
    if (registrationForm.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    const data = { ...form };
    console.log("Form data:", data);
    userActions
      .register(data)
      .then(() => {
        setError(null);
        if (typeof onRegisterSuccess === "function") {
          onRegisterSuccess();
        }
      })
      .catch((err) => {
        let errMsg = "An error occurred";
        if (err.response?.data) {
          errMsg = err.response.data.message || err.response.data.detail;
        } else if (err.message) {
          errMsg = err.message;
        }
        setError(errMsg);
        console.error("Registration error:", err);
      });
  };

  const handleEmailChange = useCallback(
    (e) => setForm((prevForm) => ({ ...prevForm, email: e.target.value })),
    []
  );
  const handlePasswordChange = useCallback(
    (e) => setForm((prevForm) => ({ ...prevForm, password: e.target.value })),
    []
  );
  const handleFirstNameChange = useCallback(
    (e) => setForm((prevForm) => ({ ...prevForm, first_name: e.target.value })),
    []
  );

  const handleLastNameChange = useCallback(
    (e) => setForm((prevForm) => ({ ...prevForm, last_name: e.target.value })),
    []
  );

  console.log("Form state:", form);

  return (
    <Form
      id="registration-form"
      className="border p-4 rounded"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          value={form.first_name}
          required
          type="text"
          placeholder="Enter first name"
          onChange={handleFirstNameChange}
          />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          value={form.last_name}
          required
          type="text"
          placeholder="Enter last name"
          onChange={handleLastNameChange}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          value={form.email}
          onChange={handleEmailChange}
          required
          type="email"
          placeholder="Enter email"
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid email.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Password</Form.Label>
        <Form.Control
          value={form.password}
          minLength="8"
          onChange={handlePasswordChange}
          required
          type="password"
          placeholder="Password"
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid password.
        </Form.Control.Feedback>
      </Form.Group>

      <div className="text-content text-danger">{error && <p>{error}</p>}</div>

      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
}

export default RegistrationForm;
