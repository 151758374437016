import React, { useEffect, useState, useCallback, useContext } from "react";
import { Container, Row, Col  } from "react-bootstrap";
import ItemsList from "../components/ItemsList";
import PaginationComponent from "../components/PaginationComponent";
import { FilterContext } from "../context/FilterContext";
import { AuthContext } from "../context/AuthContext"; // Import AuthContext
import axios from "axios";
import Disclaimer from "../components/Disclaimer";

const NotSignedIn = () => {
  const { isAuthenticated } = useContext(AuthContext); // Get isAuthenticated
  const { filters } = useContext(FilterContext);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 16;
  const [hasAttemptedFetch, setHasAttemptedFetch] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const notSignedInUrl = process.env.REACT_APP_NOTSIGNEDIN_URL;

  const fetchData = useCallback(
    (page) => {
      if (isAuthenticated) {
        // If authenticated, return without making the call
        console.log(
          "User is authenticated. Not fetching data for NotSignedIn."
        );
        return;
      }

      console.log("Fetching data for page:", page);
      
      
      return axios.get(notSignedInUrl, {
          params: {
            page,
            ...filters,
            
        },
        })
        .then((result) => {
          console.log("Data fetched successfully:", result.data);
          setData(result.data.results);
          setTotalCount(result.data.count);
          return result;
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
        });
    },
    [filters, isAuthenticated, notSignedInUrl] // Include isAuthenticated in dependency array
  );

  useEffect(() => {
    if (!hasAttemptedFetch) {
      if (
        filters.category !== "" &&
        filters.state !== "" &&
        filters.zipcode !== ""
      ) {
        // The filters have populated, we can fetch now
        console.log("Calling fetchData with currentPage:", currentPage);
        fetchData(currentPage);
        setHasAttemptedFetch(true);
      } else {
        // The filters have not populated yet, wait for 5 seconds before fetching
        const timer = setTimeout(() => {
          console.log("Calling fetchData with currentPage:", currentPage);
          fetchData(currentPage);
          setHasAttemptedFetch(true);
        }, 5000);

        // Make sure to clear the timer if the component unmounts before the timeout completes
        return () => clearTimeout(timer);
      }
    }
  }, [fetchData, currentPage, hasAttemptedFetch, filters]);

  useEffect(() => {
    console.log("Calling fetchData with currentPage:", currentPage);
    fetchData(currentPage);
  }, [fetchData, currentPage]);

  useEffect(() => {
    if (data.length > 0) {
      const timer = setTimeout(() => {
        setShowDisclaimer(true);
      }, 10000); // 10000 milliseconds = 1 seconds

      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [data]);

  const onPageChange = (pageNumber) => {
    console.log("Page changed to:", pageNumber); // Add console.log statement
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    const onUnload = (event) => {
      // Clear local/session storage here
      localStorage.clear();
      sessionStorage.clear();
      // And/or clear cookies if needed
    };

    // Attach the event listener when the component mounts
    window.addEventListener("beforeunload", onUnload);

    // Return a cleanup function that will be called when the component unmounts
    return () => {
      // Remove the event listener
      window.removeEventListener("beforeunload", onUnload);
    };
  }, []); // Empty dependency array so this effect runs once on mount and cleans up on unmount

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs={12} md={8}>
            <ItemsList data={data} />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PaginationComponent
                onPageChange={onPageChange}
                currentPage={currentPage}
                totalPages={Math.ceil(totalCount / itemsPerPage)}
              />
            </div>
        </Col>
      </Row>
      {showDisclaimer && <Disclaimer />}
    </Container>
  );
};

export default NotSignedIn;
