import React from "react";
import image from "../5.png"; // Replace with the path to your image

const LogoutComponent = () => {
  return (
    <div className="image-container">
      <img src={image} alt="Logged out" />
      <div className="text-overlay">Thanks for visiting! See you next time.</div>
    </div>
  );
};

export default LogoutComponent;
