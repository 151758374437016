import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import CustomCard from './Card';




const ItemsList = ({ data }) => {
  return (
   
      <Row className="mb-1">
        {data.map((item) => (
          <Col xs={6} sm={6} md={4} lg={3} key={item.id} className="mb-3">
            <CustomCard item={item} />
          </Col>
        ))}
      </Row>
   
  );
};

export default ItemsList;
