import React, { useEffect, useState, useCallback, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ItemsList from "../components/ItemsList";
import PaginationComponent from "../components/PaginationComponent";
import { FilterContext } from "../context/FilterContext";

import { AuthContext } from "../context/AuthContext";

const HomePage = () => {
  const {  axiosService  } = useContext(AuthContext);
  const { filters, defaultFilters } = useContext(FilterContext);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 16;
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);

  // Separate data fetching logic into its own function
  const fetchDataForPage = useCallback(
    async (pageNumber) => {
      try {
        console.log("Fetching data for page:", pageNumber);
        const result = await axiosService.get("/home", {
          // Use axiosInstance instead of axiosService
          params: {
            page: pageNumber,
            ...(filters ? filters : defaultFilters),
          },
        });
        console.log("Fetched data:", result.data);
        setData(result.data.results);
        setTotalCount(result.data.count);
        setNextPage(result.data.next ? result.data.next.split("=")[1] : null);
        setPreviousPage(
          result.data.previous ? result.data.previous.split("=")[1] : null
        );
      } catch (error) {
        console.log("Error:", error.message);
      }
    },
    [ filters, defaultFilters, axiosService] // Include axiosInstance in the dependency array
  );

  // Effect to fetch data when filters change, and reset to page 1
  useEffect(() => {
    setCurrentPage(1);
    fetchDataForPage(1);
  }, [filters, fetchDataForPage]);

  // Callback for handling page changes
  const onPageChange = useCallback(
    (pageNumber) => {
      console.log("Page changed to:", pageNumber);
      setCurrentPage(pageNumber);
      fetchDataForPage(pageNumber); // Manually call fetch function
    },
    [fetchDataForPage]
  );

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs={12} md={8}>
          <ItemsList data={data} />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <PaginationComponent
              onPageChange={onPageChange}
              currentPage={currentPage}
              totalPages={Math.ceil(totalCount / itemsPerPage)}
              nextPage={nextPage}
              previousPage={previousPage}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default HomePage;
