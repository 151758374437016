import React, { useCallback } from "react";
import { Modal } from "react-bootstrap";
import RegistrationForm from "../components/authentication/RegistrationForm";
import { useNavigate } from "react-router-dom";
import GoogleLoginButton from "../components/authentication/GoogleLogin";

const Registration = React.memo(({ showModal, onClose }) => {
  const navigate = useNavigate();
  const handleRegisterSuccess = useCallback(() => {
    onClose();
    navigate("/");
  }, [onClose, navigate]);
  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Register</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <RegistrationForm onRegisterSuccess={handleRegisterSuccess} />
        <div className="mt-3 text-center">
          <p>
            <strong>Or</strong>
          </p>
        </div>
        <div className="google-button-container">
          <GoogleLoginButton onGoogleLoginSuccess={handleRegisterSuccess} />
        </div>
      </Modal.Body>
    </Modal>
  );
});
export default Registration;
