import React, { useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { AuthContext } from "../../context/AuthContext";

// A form component for logging in
function LoginForm({ onLoginSuccess }) {
  // State variables
  const [validated, setValidated] = useState(false); // Indicates if the form has been validated
  const [form, setForm] = useState({
    // Holds the form data
    email: "",
    password: "",
  });
  const [error, setError] = useState(null); // Holds the error message, if any
  const { userActions } = useContext(AuthContext); // Accesses the user actions from the authentication context

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    const loginForm = event.currentTarget;
    if (loginForm.checkValidity() === false) {
      setValidated(true);
      return;
    }
    const data = {
      email: form.email,
      password: form.password,
    };
    console.log("Submitting form:", data);
    userActions
      .login(data)
      .then(() => {
        if (typeof onLoginSuccess === "function") {
          onLoginSuccess();
        }
      })
      .catch((err) => {
        if (err && err.request) {
          setError(err.request.response);
        } else if (err && err.message) {
          setError(err.message);
        } else {
          setError("An unknown error occurred.");
        }
      });
  };

  console.log("Rendering LoginForm"); // Debugging statement

  // JSX markup for the login form
  return (
    <Form
      id="registration-form"
      className="border p-4 rounded"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          value={form.email}
          onChange={(e) => setForm({ ...form, email: e.target.value })}
          required
          type="email" // Uses email type for better validation
          placeholder="Enter email"
        />
        <Form.Control.Feedback type="invalid">
          This field is required. // Error message for invalid input
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Password</Form.Label>
        <Form.Control
          value={form.password}
          minLength="8"
          onChange={(e) => setForm({ ...form, password: e.target.value })}
          required
          type="password"
          placeholder="Password"
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid password. // Error message for invalid input
        </Form.Control.Feedback>
      </Form.Group>

      <div className="text-content text-danger">{error && <p>{error}</p>}</div>

      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
}

export default LoginForm;
