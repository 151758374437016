import { createContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const baseURL = apiUrl;
  const [hasLoggedOut, setHasLoggedOut] = useState(false); //new
  const initialAuthState = JSON.parse(localStorage.getItem("auth")) || {};
  const [authData, setAuthData] = useState(initialAuthState);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!initialAuthState.access
  );


  const getAccessToken = () => {
    return authData.access;
  };

  const getRefreshToken = useCallback(() => {
    return authData?.refresh;
  }, [authData?.refresh]);

  const getUser = useCallback(() => {
    return authData?.user;
  }, [authData?.user]);

  const setUserData = useCallback((data) => {
    console.log("Saving user data:", data);
    const newAuthData = {
      access: data.access,
      refresh: data.refresh,
      user: data.user,
    };
    localStorage.setItem("auth", JSON.stringify(newAuthData));
    setAuthData(newAuthData); // Update the authData state variable
  }, []);

  const axiosInstance = axios.create({ baseURL });

  // Function to refresh the token
   const refreshAuthLogic = (failedRequest) =>
     axiosInstance
       .post(`${baseURL}/auth/refresh`, {
         refresh: getRefreshToken(),
       })
       .then((response) => {
         console.log("Received new token data:", response.data);
         setUserData(response.data);
         failedRequest.response.config.headers[
           "Authorization"
         ] = `Bearer ${response.data.access}`;
         console.log("Updated failed request:", failedRequest.response.config);
         return Promise.resolve();
       });

  // Attach the interceptor to the Axios instance
  createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

  axiosInstance.interceptors.request.use((config) => {
    const accessToken = getAccessToken();
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  });

  const isLoggedIn = useCallback(() => {
    return !!authData.access;
  }, [authData.access]);

  const login = (data) => {
    return axios
      .post(`${baseURL}/auth/login`, data)
      .then((res) => {
        if ((res.status >= 200 && res.status < 300) || res.status === 201) {
          setUserData(res.data);
          setIsAuthenticated(true);
          setHasLoggedOut(false); //new
          navigate("/");
        } else {
          throw new Error(res.data.error);
        }
      })
      .catch((error) => {
        throw new Error(error.response?.data?.error || error);
      });
  };

  const loginWithGoogle = (data) => {
    console.log("Sending to backend:", data); // This will print the payload being sent
    return axios
      .post(`${baseURL}/auth/googlelogin/`, data)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setUserData(res.data);
          setIsAuthenticated(true);
          setHasLoggedOut(false); //new
        } else {
          throw new Error(res.data.error);
        }
      })
      .catch((error) => {
        throw new Error(error.response?.data?.error || error);
      });
  };


  const register = (data) => {
    return axios.post(`${baseURL}/auth/register`, data).then((res) => {
      if ((res.status >= 200 && res.status < 300) || res.status === 201) {
        setUserData(res.data);
        setIsAuthenticated(true);
        navigate("/");
      }
    });
  };

  const logout = () => {
    const refreshToken = getRefreshToken();
    axiosInstance // <-- Ensure you're using axiosInstance
      .post(`${baseURL}/auth/logout`, { refresh: refreshToken })
      .then(() => {
        localStorage.removeItem("auth");
        setIsAuthenticated(false);
        setHasLoggedOut(true); //new
        navigate("/loggedout");
      })
      .catch((error) => {
        console.error("Logout failed:", error);
        setIsAuthenticated(false);
        setHasLoggedOut(true); //new
        navigate("/loggedout");
      });
  };


  useEffect(() => {
    setIsAuthenticated(isLoggedIn());
  }, [isLoggedIn]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        hasLoggedOut, //new
        setHasLoggedOut, //new
        userActions: {
          login,
          loginWithGoogle,
          register,
          logout,
          getUser,
          getAccessToken,
          getRefreshToken,
          setUserData,
        },
        axiosService: axiosInstance,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
